import { reactRouterV6BrowserTracingIntegration as n, wrapCreateBrowserRouter as s, init as i, captureException as c } from "@sentry/react";
import { useEffect as a } from "react";
import { useLocation as p, useNavigationType as u, createRoutesFromChildren as m, matchRoutes as E } from "react-router";
import { createBrowserRouter as d } from "react-router-dom";
const f = {
  dsn: "https://6b916b2490634b36a5434c31d23c5797@o372675.ingest.sentry.io/5896648",
  integrations: [
    n({
      useEffect: a,
      useLocation: p,
      useNavigationType: u,
      createRoutesFromChildren: m,
      matchRoutes: E
    })
  ],
  tracesSampleRate: 1
}, R = s(d), h = {
  init: (r = {}) => {
    const t = process.env.NODE_ENV === "production", o = window.location.href.includes("stage") ? "stage" : "production", e = process.env.RELEASE_NAME;
    t && e && i({
      ...f,
      ...r,
      environment: o,
      release: e
    });
  },
  trackError: c,
  sentryCreateBrowserRouter: R
};
export {
  h as SentryService
};
